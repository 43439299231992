import { useEffect } from "react";

import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { graphql } from "react-relay";
import { useLazyLoadQuery } from "react-relay/hooks";

import RelayEnvironment from "RelayEnvironment";

import createUrlConfig from "./createUrlConfig";

import type { ExternalLinksQuery } from "./__generated__/ExternalLinksQuery.graphql";

const ExternalLinks = () => {
  const data = useLazyLoadQuery<ExternalLinksQuery>(
    graphql`
      query ExternalLinksQuery {
        UrlConfig {
          docs
        }
      }
    `,
    {},
  );

  useEffect(() => {
    createUrlConfig(RelayEnvironment);
  }, []);

  return (
    <Box sx={{ display: "flex" }}>
      {data.UrlConfig?.docs ? (
        <Link href={data.UrlConfig.docs} sx={{ marginLeft: 3 }}>
          <Typography color="textSecondary" variant="body2">
            Documentation
          </Typography>
        </Link>
      ) : null}
    </Box>
  );
};

export default ExternalLinks;
